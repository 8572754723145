import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ExitIframe() {
  const { search } = useLocation();

  useEffect(() => {
    (async function () {
      const params = new URLSearchParams(search);
      const redirectUri = params.get("redirectUri");

      if (!redirectUri) {
        return;
      }

      try {
        const decodedRedirectUri = new URL(decodeURIComponent(redirectUri));
        // console.log("🚀 Redirecting to:", decodedRedirectUri);

        // Ensure Shopify expects the correct hostname
        if (
          ["admin.shopify.com", window.location.hostname].includes(decodedRedirectUri.hostname) ||
          decodedRedirectUri.hostname.endsWith(".myshopify.com")
        ) {
          window.open(redirectUri, "_top");
        } else {
          // console.log("❌ Invalid Redirect Hostname:", decodedRedirectUri.hostname);
        }
      } catch (e) {
        // console.log("❌ Invalid URL:", e);
      }
    })();
  }, [search]);

  return <div>Redirecting...</div>;
}
