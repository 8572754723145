import { Card, Checkbox, Box, Layout, Page, SkeletonBodyText, SkeletonPage, Divider, BlockStack, Text } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { Setting } from "../../app/models/Setting";
import { useStore } from "../../app/stores/store";
import PriceRestoreCard from "./PriceRestoreCard";

const SyncPage = () => {
  const { settingStore } = useStore();
  const { currentSettings: settings, loadingUpdate, loadingInitial, loadSettings } = settingStore;

  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [syncChecked, setSyncChecked] = useState(false);

  const handleSyncChange = useCallback(
    (value: boolean) => setSyncChecked(value),
    []
  );

  const [productSyncChecked, setProductSyncChecked] = useState(false);
  const handleProductSyncChange = useCallback(
    (value: boolean) => {
      setProductSyncChecked(value);
      if (!value) {
        setVariantPriceSyncChecked(false);
        setPriceListSyncChecked(false);
        setInventorySyncChecked(false);
        setSalesCatSyncChecked(false);
      }
    },
    []
  );

  const [variantPriceSyncChecked, setVariantPriceSyncChecked] = useState(false);
  const handleVariantPriceSyncChange = useCallback(
    (value: boolean) => {
      setVariantPriceSyncChecked(value);
      if (value) {
        setProductSyncChecked(true);
      }
    },
    []
  );

  const [priceListSyncChecked, setPriceListSyncChecked] = useState(false);
  const handlePriceListSyncChange = useCallback(
    (value: boolean) => {
      setPriceListSyncChecked(value);
      if (value) {
        setProductSyncChecked(true);
      }
    },
    []
  );

  const [inventorySyncChecked, setInventorySyncChecked] = useState(false);
  const handleInventorySyncChange = useCallback(
    (value: boolean) => {
      setInventorySyncChecked(value);
      if (value) {
        setProductSyncChecked(true);
        setLocationSyncChecked(true);
      }
    },
    []
  );

  const [salesCatSyncChecked, setSalesCatSyncChecked] = useState(false);
  const handleSalesCatSyncChange = useCallback(
    (value: boolean) => {
      setSalesCatSyncChecked(value);
      if (value) {
        setProductSyncChecked(true);
      }
    },
    []
  );

  const [companyLocationSyncChecked, setCompanyLocationSyncChecked] = useState(false);
  const handleCompanyLocationSyncChange = useCallback(
    (value: boolean) => {
      setCompanyLocationSyncChecked(value);
      if (!value) {
        setCustomerLevelSyncChecked(false);
        setSalesRepSyncChecked(false);
        setCompanyLocationCreditSyncChecked(false);
      }
    },
    []
  );

  const [companyLocationCreditSyncChecked, setCompanyLocationCreditSyncChecked] = useState(false);
  const handleCompanyLocationCreditSyncChange = useCallback(
    (value: boolean) => {
      setCompanyLocationCreditSyncChecked(value);
      if (value) {
        setCompanyLocationSyncChecked(true);
      }
    },
    []
  );

  const [customerLevelSyncChecked, setCustomerLevelSyncChecked] = useState(false);
  const handleCustomerLevelSyncChange = useCallback(
    (value: boolean) => {
      setCustomerLevelSyncChecked(value);
      if (value) {
        setCompanyLocationSyncChecked(true);
      }
    },
    []
  );

  const [salesRepSyncChecked, setSalesRepSyncChecked] = useState(false);
  const handleSalesRepSyncChange = useCallback(
    (value: boolean) => {
      setSalesRepSyncChecked(value);
      if (value) {
        setCompanyLocationSyncChecked(true);
      }
    },
    []
  );

  const [orderSyncChecked, setOrderSyncChecked] = useState(false);
  const handleOrderSyncChange = useCallback(
    (value: boolean) => {
      setOrderSyncChecked(value);
      if (!value) {
        setFulfillmentSyncChecked(false);
      }
    },
    []
  );

  const [fulfillmentSyncChecked, setFulfillmentSyncChecked] = useState(false);
  const handleFulfillmentSyncChange = useCallback(
    (value: boolean) => {
      setFulfillmentSyncChecked(value);
      if (value) {
        setOrderSyncChecked(true);
      }
    },
    []
  );

  const [locationSyncChecked, setLocationSyncChecked] = useState(false);
  const handleLocationSyncChange = useCallback(
    (value: boolean) => {
      setLocationSyncChecked(value);
      if (!value) {
        setInventorySyncChecked(false);
      }
    },
    []
  );

  useEffect(() => {
    const fetchSettings = async () => {
      await settingStore.loadSettings();
      setSettingsLoaded(true);
    }

    if (!settingsLoaded) {
      fetchSettings();
    } else if (settings) {
      setSyncChecked(settings.syncEnabled);
      setProductSyncChecked(settings.productSyncEnabled);
      setVariantPriceSyncChecked(settings.variantPriceSyncEnabled);
      setPriceListSyncChecked(settings.priceListSyncEnabled)
      setInventorySyncChecked(settings.inventorySyncEnabled);
      setSalesCatSyncChecked(settings.salesCatSyncEnabled);
      setCompanyLocationSyncChecked(settings.companyLocationSyncEnabled);
      setCompanyLocationCreditSyncChecked(settings.companyLocationCreditSyncEnabled);
      setCustomerLevelSyncChecked(settings.customerLevelSyncEnabled);
      setSalesRepSyncChecked(settings.salesRepSyncEnabled);
      setOrderSyncChecked(settings.orderSyncEnabled);
      setFulfillmentSyncChecked(settings.fulfillmentSyncEnabled);
      setLocationSyncChecked(settings.locationSyncEnabled);
    }

  }, [settingsLoaded, settings, settingStore]);

  const handleSaveSettings = async () => {
    let newSetting = {
      syncEnabled: syncChecked,
      productSyncEnabled: productSyncChecked,
      variantPriceSyncEnabled: variantPriceSyncChecked,
      priceListSyncEnabled: priceListSyncChecked,
      inventorySyncEnabled: inventorySyncChecked,
      salesCatSyncEnabled: salesCatSyncChecked,
      companyLocationSyncEnabled: companyLocationSyncChecked,
      companyLocationCreditSyncEnabled: companyLocationCreditSyncChecked,
      customerLevelSyncEnabled: customerLevelSyncChecked,
      salesRepSyncEnabled: salesRepSyncChecked,
      orderSyncEnabled: orderSyncChecked,
      fulfillmentSyncEnabled: fulfillmentSyncChecked,
      locationSyncEnabled: locationSyncChecked,
    } as Setting;

    setUpdateInProgress(true);

    await settingStore.updateSettings(newSetting);

    setUpdateInProgress(false);
  };

  if (!settingsLoaded)
    return (
      <SkeletonPage title="Traverse Data SYNC">
        <Layout>
          <Layout.Section>
            <BlockStack gap="500">
              <Card>
                <SkeletonBodyText />
              </Card>
              <Card>
                <SkeletonBodyText />
              </Card>
            </BlockStack>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );

  return (
    <Page title="Sync settings"
      primaryAction={{
        content: "Save",
        loading: updateInProgress,
        onAction: handleSaveSettings,
      }}>
      <Layout>
        <Layout.Section>

          <BlockStack gap="500">
            <PriceRestoreCard />
            <Card>
              <BlockStack gap="400">
                <Checkbox
                  label="Enable data synchronization"
                  checked={syncChecked}
                  onChange={handleSyncChange}
                />

                <Divider />
               
                <BlockStack gap="200">
                <Text as="p" variant="bodyMd">Product-related syncs to include</Text>
                  <Checkbox
                    label="Product"
                    checked={productSyncChecked}
                    onChange={handleProductSyncChange}
                  />
                  <Checkbox
                    label="Variant pricing"
                    checked={variantPriceSyncChecked}
                    onChange={handleVariantPriceSyncChange}
                  />
                  <Checkbox
                    label="Price lists"
                    checked={priceListSyncChecked}
                    onChange={handlePriceListSyncChange}
                  />
                  <Checkbox
                    label="Inventory"
                    checked={inventorySyncChecked}
                    onChange={handleInventorySyncChange}
                  />
                  <Checkbox
                    label="Sales category"
                    checked={salesCatSyncChecked}
                    onChange={handleSalesCatSyncChange}
                  />
                </BlockStack>
                
                <Divider />

                <BlockStack gap="200">
                  <Text as="p" variant="bodyMd">Location-related syncs to include</Text>
                  <Checkbox
                    label="Company location"
                    checked={companyLocationSyncChecked}
                    onChange={handleCompanyLocationSyncChange}
                  />
                  <Checkbox
                    label="Company location credit"
                    checked={companyLocationCreditSyncChecked}
                    onChange={handleCompanyLocationCreditSyncChange}
                  />
                  <Checkbox
                    label="Customer level"
                    checked={customerLevelSyncChecked}
                    onChange={handleCustomerLevelSyncChange}
                  />
                  <Checkbox
                    label="Sales representative"
                    checked={salesRepSyncChecked}
                    onChange={handleSalesRepSyncChange}
                  />
                </BlockStack>

                <Divider />

                <BlockStack gap="200">
                  <Text as="p" variant="bodyMd">Order-related syncs to include</Text>
                  <Checkbox
                    label="Order"
                    checked={orderSyncChecked}
                    onChange={handleOrderSyncChange}
                  />
                  <Checkbox
                    label="Fulfillment"
                    checked={fulfillmentSyncChecked}
                    onChange={handleFulfillmentSyncChange}
                  />
                </BlockStack>

                <Divider />

                <BlockStack gap="200">
                  <Text as="p" variant="bodyMd">Other syncs to include</Text>
                  <Checkbox
                    label="Location"
                    checked={locationSyncChecked}
                    onChange={handleLocationSyncChange}
                  />
                </BlockStack>

              </BlockStack>
            </Card>



          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default SyncPage;
