import { Card, Checkbox, DataTable, Layout, Page, SkeletonBodyText, SkeletonPage, Box } from "@shopify/polaris";
import { useEffect } from "react";
import { useStore } from "../../app/stores/store";

const AccountPage = () => {
  const { commonStore, userStore } = useStore();

  useEffect(() => {        
    if (commonStore.token) {
      userStore.getUser().finally(() => commonStore.setAppLoaded());
      console.log(userStore.user);
    } else {
      commonStore.setAppLoaded();        
    }
  }, [commonStore, userStore]);

  const rows = [        
    ['Domain', userStore.user?.shopDomain],
    // ['Activated',  userStore.user?.subscription.activatedOn]
  ];

  const logout = () => {
    userStore.logout();
  }

  return (
    <Page 
      title="Account"   
      primaryAction={{
        content: 'Logout',
        onAction: () => logout()
      }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <Box padding={"400"}>
              <DataTable
                columnContentTypes={[]}
                headings={[]}
                rows={rows}
              />
            </Box>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default AccountPage;
