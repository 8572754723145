import { Card, Checkbox, Layout, Page, PageActions, SkeletonBodyText, SkeletonPage, Box, Text } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { Setting } from "../../app/models/Setting";
import { useStore } from "../../app/stores/store";

const SettingsPage = () => {
  const { settingStore } = useStore();
  const { currentSettings: settings, loadingUpdate, loadingInitial, loadSettings } = settingStore;

  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [syncChecked, setSyncChecked] = useState(false);

  const handleSyncChange = useCallback(
    (value: boolean) => setSyncChecked(value),
    []
  );

  useEffect(() => {
    const fetchSettings = async () => {
      await settingStore.loadSettings();
      setSettingsLoaded(true);
    };

    if (!settingsLoaded) {
      fetchSettings();
    } else if (settings) {
      setSyncChecked(settings.syncEnabled);
    }
  }, [settingsLoaded, settingStore, settings]);

  const handleSaveSettings = async () => {
    const newSetting = {
      syncEnabled: syncChecked,
    } as Setting;

    setUpdateInProgress(true);
    await settingStore.updateSettings(newSetting);
    setUpdateInProgress(false);
  };

  if (!settingsLoaded)
    return (
      <SkeletonPage title="Settings">
        <Layout>
          <Layout.Section>
            <Card>
              <Box padding="400">
                <SkeletonBodyText />
              </Box>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );

  return (
    <Page title="Settings">
      <Layout>
        <Layout.Section>
          <Card>
            <Box padding="400">
              <Checkbox
                label="Enable data synchronization"
                checked={syncChecked}
                onChange={handleSyncChange}
              />
              <br />
              <Checkbox
                label="Include pricing data"
                checked={true}
                disabled={true}
              />
            </Box>
          </Card>
        </Layout.Section>
      </Layout>
      <PageActions
        primaryAction={{
          content: "Save",
          loading: updateInProgress,
          onAction: handleSaveSettings,
        }}
      />
    </Page>
  );
};

export default SettingsPage;
