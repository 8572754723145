import {
  LegacyCard,
  Text,
  useIndexResourceState,
  IndexTable,
  useBreakpoints,
  IndexTableRowProps,
  IndexTableProps,
  Badge,
  SkeletonPage,
  Layout,
  Card,
  TextContainer,
  SkeletonDisplayText,
  SkeletonBodyText,
} from '@shopify/polaris';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useStore } from "../../app/stores/store";
import { SyncLog } from '../../app/models/SyncLog';
import { ErrorLog } from '../../app/models/ErrorLog';

const SyncStatusTable = () => {
  const { syncLogStore } = useStore();
  const { syncLogs, loadingInitial } = syncLogStore;

  const [syncLogsLoaded, setSyncLogsLoaded] = useState(false);

  useEffect(() => {
    const fetchSyncLogs = async () => {
      await syncLogStore.loadSyncLogs();
      setSyncLogsLoaded(true);
    };

    if (!syncLogsLoaded) {
      fetchSyncLogs();
    }
  }, [syncLogsLoaded, syncLogStore]);

  const syncFrequencies: { [key: string]: string } = {
    Customers: '15 min',
    Products: '15 min',
    ProductVariants: '15 min',
    Orders: '15 min',
    OrderFulfillments: '15 min',
    CompanyLocationCredit: '15 min',
    Inventory: '15 min',
    ProductSalesCat: '4 hours',
    Locations: '4 hours',
    CompanyLocations: '4 hours',
    CustomerLevel: '4 hours',
    ShowroomsInventory: '4 hours',
    SalesReps: '4 hours',
    CompanyLocationBillingAddress: '4 hours',
    CompanyLocationShippingAddress: '4 hours',
    CompanyLocationCustomerLevel: '4 hours',
    CompanyLocationSalesRep: '4 hours',
    CompanyLocationPaymentTerms: '4 hours',
    PriceList: '24 hours',
    PriceListPrices: '24 hours',
    ProductVariantPrice: '24 hours',
  };

  const normalizeSyncObject = (syncObject: string): string => {
    const mapping: { [key: string]: string } = {
      'Product': 'Metafield', // Moving to Metafield grouping since only product update is the Sales Cat update, which is a metafield
    };
    return mapping[syncObject] || syncObject;
  };
  
  const prepareSyncObjectDisplayName = (syncObject: string): string => {
    const mapping: { [key: string]: string } = {
      'CompanyLocation': 'Company Location',
      'FulfillmentOrder': 'Fulfillment Order',
      'MetaObject': 'Metaobject',
      'ProductVariantPrice': 'Product Variant',
      'PriceList': 'Price List',
      'PriceListPrices': 'Price List'
    };
    return mapping[syncObject] || syncObject;
  };

  const normalizePropertyName = (propertyName: string): string => {
    const mapping: { [key: string]: string } = {
      'companyLocationUpdate/updateType=Billing': 'CompanyLocationBillingAddress',
      'companyLocationUpdate/updateType=Shipping': 'CompanyLocationShippingAddress',
      'metafieldsSet/metafieldsSet/companyLocations/updateType=CustomerLevel': 'CompanyLocationCustomerLevel',
      'companyLocationUpdate/updateType=SalesRep': 'CompanyLocationSalesRep',
      'companyLocationUpdate/updateType=Credit': 'CompanyLocationCredit',
      'companyLocationUpdate/updateType=PaymentTerms': 'CompanyLocationPaymentTerms',
      'companyLocations': 'CompanyLocation',
      'fulfillmentCreateV2': 'OrderFulfillments',
      'metafieldsSet/namespace=custom?key=showroom_inventory': 'ShowroomsInventory',
      'metafieldsSet/namespace=custom?key=sales_cat': 'ProductSalesCat',
      'metaobjectUpsert/type=sales_rep': 'SalesReps',
      'ProductVariantPrice': 'ProductVariantPrice',
      'productVariantUpdate': 'ProductVariantPrice',
    };
    return mapping[propertyName] || propertyName;
  };

  const preparePropertyDisplayName = (syncObject: string): string => {
    const mapping: { [key: string]: string } = {
      'companyLocationUpdate/updateType=Billing': 'Billing Address',
      'companyLocationUpdate/updateType=Shipping': 'Shipping Address',
      'metafieldsSet/metafieldsSet/companyLocations/updateType=CustomerLevel': 'Customer Level',
      'companyLocationUpdate/updateType=PaymentTerms': 'Payment Terms',
      'companyLocationUpdate/updateType=SalesRep': 'Sales Rep',
      'companyLocationUpdate/updateType=Credit': 'Credit',
      'companyLocations': 'CompanyLocation',
      'fulfillmentCreateV2': 'Fulfillments',
      'metafieldsSet/namespace=custom?key=showroom_inventory': 'Product Variant Showroom Inventory',
      'metafieldsSet/namespace=custom?key=sales_cat': 'Product Sales Cat',
      'metaobjectUpsert/type=sales_rep': 'Sales Reps',
      'productVariantUpdate': 'Prices',
    };
    return mapping[syncObject] || syncObject;
  };

  const calculateStatus = (frequency: string, completedAt: string | null, errorLogs: ErrorLog[]): string => {
    if (errorLogs && errorLogs.length > 0) {
      return 'Error';
    }
  
    if (!completedAt) {
      return 'Expired';
    }
  
    const now = dayjs();
    const lastCompleted = dayjs(completedAt);
    const diff = now.diff(lastCompleted);
  
    if (frequency === '15 min' && diff > 45 * 60 * 1000) {
      return 'Expired';
    } else if (frequency === '4 hours' && diff > 5 * 60 * 60 * 1000) {
      return 'Expired';
    } else if (frequency === '24 hours' && diff > 25 * 60 * 60 * 1000) {
      return 'Expired';
    }
  
    return 'Active';
  };
  

  interface SyncLogWithStatus extends SyncLog {
    frequency: string;
    status: string;
  }

  const groupedData: { [key: string]: SyncLogWithStatus[] } = syncLogs.reduce((groups, item) => {
    const normalizedSyncObject = normalizeSyncObject(item.syncObject);
    const normalizedPropertyName = normalizePropertyName(item.propertyName);
    const frequency =
      syncFrequencies[normalizedPropertyName] ||
      syncFrequencies[normalizedSyncObject] ||
      'Unknown';
    const status = calculateStatus(frequency, item.completedAt, item.errorLogs || []);
  
    if (!groups[normalizedSyncObject]) {
      groups[normalizedSyncObject] = [];
    }
  
    groups[normalizedSyncObject].push({
      ...item,
      frequency,
      status,
    });
  
    return groups;
  }, {} as { [key: string]: SyncLogWithStatus[] });
  

  // Define the desired order
  const desiredOrder = [
    'CompanyLocation',
    'FulfillmentOrder',
    'Metafield',
    'MetaObject',
    'Product',
    'ProductVariantPrice',
    'Inventory',
    'PriceList',
    'PriceListPrices'
  ];

  // Convert groupedData to an array and sort it based on the desired order
  const sortedGroupedData = Object.entries(groupedData)
    .sort(([a], [b]) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b))
    .map(([key, value]) => ({ syncObject: key, logs: value }));

  const renderStatusBadge = (status: string) => {
    switch (status) {
      case 'Active':
        return <Badge tone="success">Active</Badge>;
      case 'Expired':
        return <Badge tone="warning">Expired</Badge>;
      case 'Error':
        return <Badge tone="critical">Error</Badge>;
      default:
        return <Badge>{status}</Badge>;
    }
  };

  const columnHeadings = [
    { title: 'Object', id: 'object' },
    { title: 'Frequency', id: 'frequency' },
    { title: 'No. Records Last Updated', id: 'objectCount' },
    { title: 'Last Completed At', id: 'completedAt' },
    { title: 'Status', id: 'status' },
  ];

  const resourceName = {
    singular: 'sync log',
    plural: 'sync logs',
  };

  const groupedDataArray = sortedGroupedData.flatMap(group => group.logs) as unknown as { [key: string]: unknown }[];

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(groupedDataArray);

  const breakpoints = useBreakpoints();

  const loadingPageMarkup = (
    
      <Layout>
        <Layout.Section>
          <Card>
            <TextContainer>
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>

  );

  if (!syncLogsLoaded) {
    return loadingPageMarkup;
  }

  const rowMarkup = sortedGroupedData.map((group, index) => {
    const { syncObject, logs } = group;
    const subheaderId = `group-${syncObject}`;
    let selected: IndexTableRowProps['selected'] = false;

    const someLogsSelected = logs.some(({ id }) =>
      selectedResources.includes(id),
    );

    const allLogsSelected = logs.every(({ id }) =>
      selectedResources.includes(id),
    );

    if (allLogsSelected) {
      selected = true;
    } else if (someLogsSelected) {
      selected = 'indeterminate';
    }

    const syncObjectDisplayName = prepareSyncObjectDisplayName(syncObject);

    return (
      <Fragment key={subheaderId}>
        <IndexTable.Row
          rowType="subheader"
          id={subheaderId}
          position={index}
          selected={selected}
        >
          <IndexTable.Cell colSpan={5} scope="colgroup" as="th">
            {syncObjectDisplayName}
          </IndexTable.Cell>
        </IndexTable.Row>
        {logs.map(
          ({ id, syncObject, propertyName, frequency, objectCount, completedAt, status }, rowIndex) => {
            const preparedDisplayName = preparePropertyDisplayName(propertyName);
            return (
              <IndexTable.Row
                key={rowIndex}
                id={id}
                position={index + rowIndex + 1}
                selected={selectedResources.includes(id)}
              >
                <IndexTable.Cell>{preparedDisplayName}</IndexTable.Cell>
                <IndexTable.Cell>{frequency}</IndexTable.Cell>
                <IndexTable.Cell>{objectCount}</IndexTable.Cell>
                <IndexTable.Cell>{dayjs(completedAt).format('YYYY-MM-DD HH:mm:ss')}</IndexTable.Cell>
                <IndexTable.Cell>{renderStatusBadge(status)}</IndexTable.Cell>
              </IndexTable.Row>
            );
          },
        )}
      </Fragment>
    );
  });

  return (
    <LegacyCard>
      <IndexTable
        condensed={breakpoints.smDown}
        onSelectionChange={handleSelectionChange}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        resourceName={resourceName}
        itemCount={groupedDataArray.length}
        headings={columnHeadings as IndexTableProps['headings']}
        selectable={false}
      >
        {rowMarkup}
      </IndexTable>
    </LegacyCard>
  );
};

export default observer(SyncStatusTable);
