import axios, { AxiosResponse } from 'axios';
import { store } from '../stores/store';
import { User, UserFormValues } from '../models/User';
import { ShopifyAuth } from '../models/ShopifyAuth';
import { Setting } from '../models/Setting';
import { SyncLog } from '../models/SyncLog';
import { Group, GroupFormValues } from '../models/Group';
import { PricePullHistory, Pricing } from '../models/Pricing';

//axios.defaults.baseURL = 'http://localhost:5000/api';
//axios.defaults.baseURL = 'https://rh.ngrok.app/api';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// this sends bearer token (if available) with each request
axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config;
})

// Used to create fake delay, for testing loading states
const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    })
}

// Used to create fake delay, for testing loading states
axios.interceptors.response.use(async response => {
    try {
        //await sleep(500);
        return response;
    } catch (error) {
        console.log(error);
        return await Promise.reject(error);
    }
})

const responseBody = <T> (response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T> (url: string) => axios.get<T>(url).then(responseBody),
    post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T> (url: string) => axios.delete<T>(url).then(responseBody),
}

const Account = {
    auth: (user: UserFormValues) => requests.post<string>('/account/auth', user),    
    embeddedauth: (user: ShopifyAuth) => requests.post<string>('/account/embeddedauth', user),
    embeddedlogin: (user: ShopifyAuth) => requests.post<User>('/account/embeddedlogin', user),
    login: (user: ShopifyAuth) => requests.post<User>('/account/login', user),
    current: () => requests.get<User>('/account')    
}

const Settings = {
    details: () => requests.get<Setting>('/settings'),
    update: (setting: Setting) => axios.put<void>(`/settings/`, setting),
}

const SyncLogInfo = {
    details: () => requests.get<SyncLog>('/synclog/details'),
    list: () => requests.get<SyncLog[]>('/synclog'),  
}


const PricingInfo = {
    details: () => requests.get<Pricing>('/pricing'),
    restore: (pricing: PricePullHistory) => axios.post<PricePullHistory>(`/pricing/`, pricing),
}

const Groups = {
    list: () => requests.get<Group[]>('/groups'),
    details: (id: string) => requests.get<Group>(`/groups/${id}`),
    create: (group: GroupFormValues) => axios.post<void>('/groups', group),
    update: (group: GroupFormValues) => axios.put<void>(`/groups/${group.id}`, group),
    delete: (id: string) => axios.delete<void>(`/groups/${id}`)
}

const agent = {
    Account,
    Settings,
    Groups,
    SyncLogInfo,
    PricingInfo
}
 
export default agent;
