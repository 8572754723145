import React from "react";
import ReactDOM from 'react-dom/client';
import { AppProvider, Frame } from "@shopify/polaris";
import { store, StoreContext } from "./app/stores/store";
import "@shopify/polaris/build/esm/styles.css";
import "./styles.css";
import { BrowserRouter, RouterProvider } from "react-router-dom";
import { router } from "./app/router/Routes";
import reportWebVitals from "./reportWebVitals";
import Shopify, { ApiVersion } from "@shopify/shopify-api";
import { NavMenu } from "@shopify/app-bridge-react";
// export const history = createBrowserHistory();


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <AppProvider
    i18n={{}}>
      <NavMenu>
      <a href="/" rel="home">
        Home
      </a>
      <a href="/dashboard">Dashboard</a>
      <a href="/sync">Sync</a>
    </NavMenu>
      <StoreContext.Provider value={store}>
        <RouterProvider router={router} />
      </StoreContext.Provider>
  </AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
