import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { SyncLog } from '../models/SyncLog';

export default class SyncLogStore {
    syncLogRegistry = new Map<string, SyncLog>();
    selectedSyncLog: SyncLog | undefined = undefined;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    get syncLogs() {
        return Array.from(this.syncLogRegistry.values());
    }

    get syncLog() {
        return this.selectedSyncLog;
    }

    loadSyncLog = async () => {
        this.setLoadingInitial(true);
        try {
            const syncLog = await agent.SyncLogInfo.details();
            runInAction(() => {
                this.setSyncLog(syncLog);
                this.setLoadingInitial(false);
            });
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadSyncLogs = async () => {
        this.setLoadingInitial(true);
        try {
            const syncLogs = await agent.SyncLogInfo.list();
            runInAction(() => {
                syncLogs.forEach((log: SyncLog) => {
                    this.syncLogRegistry.set(log.id, log);
                });
                this.setLoadingInitial(false);
            });
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    private setSyncLog = (syncLog: SyncLog) => {
        this.selectedSyncLog = syncLog;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    clearSelectedSyncLog = () => {
        this.selectedSyncLog = undefined;
    }
}
