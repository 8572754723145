import { useState, useEffect, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
    Button,
    Form,
    FormLayout,
    Frame,
    Layout,
    Loading,
    Page,
    TextField,
} from "@shopify/polaris";
import { useStore } from "../../app/stores/store";
import { UserFormValues } from "../../app/models/User";
import { ShopifyAuth } from "../../app/models/ShopifyAuth";

const LoginPage = () => {
    const { userStore } = useStore();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [storeDomain, setStoreDomain] = useState("");
    const [authParamsPresent, setAuthParamsPresent] = useState(false);

    useEffect(() => {
        const rawQueryParams = searchParams.toString();

        const authParams: ShopifyAuth = {
            entire_raw_params: rawQueryParams,
            code: searchParams.get("code") ?? "",
            embedded: searchParams.get("embedded") ?? "",
            hmac: searchParams.get("hmac") ?? "",
            host: searchParams.get("host") ?? "",
            id_token: searchParams.get("id_token") ?? "",
            link_source: searchParams.get("link_source") ?? "",
            locale: searchParams.get("locale") ?? "",
            session: searchParams.get("session") ?? "",
            shop: searchParams.get("shop") ?? "",
            state: searchParams.get("state") ?? "",
            timeStamp: searchParams.get("timestamp") ?? "",
        };

        if (Object.values(authParams).some((value) => value)) {
            setAuthParamsPresent(true);

            const authenticateUser = async () => {
                if (authParams.code && authParams.state) {
                    //console.log("Performing regular oAuth login.");
                    await userStore.login(authParams);
                } else if (authParams.embedded && authParams.id_token) {
                    //console.log("Performing embedded app oAuth login.");
                    await userStore.embeddedLogin(authParams);
                }
            };

            authenticateUser();
        }
    }, [searchParams, userStore]);

    useEffect(() => {
        if (userStore.isLoggedIn) navigate("/home");
    }, [userStore.isLoggedIn, navigate]);

    const handleSubmit = () => {
        userStore.authenticate({ shopDomain: storeDomain } as UserFormValues);
    };

    const handleShopDomainChange = useCallback((value: string) => {
        setStoreDomain(value);
    }, []);

    if (authParamsPresent) {
        return (
            <div style={{ height: "100px" }}>
                <Frame>
                    <Loading />
                </Frame>
            </div>
        );
    }

    return (
        <Page title="Login">
            <Layout>
                <Form onSubmit={handleSubmit}>
                    <FormLayout>
                        <TextField
                            value={storeDomain}
                            onChange={handleShopDomainChange}
                            label="Shopify store URL"
                            type="text"
                            helpText="Enter your Shopify store URL to login. For example store.myshopify.com"
                            autoComplete="off"
                        />
                        <Button submit>Login</Button>
                    </FormLayout>
                </Form>
            </Layout>
        </Page>
    );
};

export default observer(LoginPage);
