import { Layout, Page } from "@shopify/polaris";
import { observer } from "mobx-react-lite";
import React from "react";
import SyncLogInfo from "../sync/SyncLogInfo";
import SyncStatusTable from "../sync/SyncStatusTable";

const Dashboard = () => {  
  
  return (
    <Page title="Dashboard">
      <Layout>
        <Layout.Section> 
          <SyncStatusTable />
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default observer(Dashboard);
